<template>
  <div class="login">
    <div class="login-box">
      <div class="title">
        <img src="../assets/logo.png">
        <div class="name">
          数字化智慧市场管理中心
        </div>
        <div class="company">
          重庆牧予科技有限公司
        </div>
      </div>
      <el-form class="form" :model="form" :rules="rules" ref="loginform">
        <el-form-item prop="user">
          <el-input v-model.trim="form.user" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model.trim="form.password" type="password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button :loading="loading" @click="login" type="primary" style="width: 100%;height: 42px;">登录</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="support">
      <img src="../assets/wh.png">
      <span>技术支持:华有云</span>
    </div>
  </div>
</template>

<script>
import { createInit } from "@/plugin/navlist";
export default {
  name: 'login',
  data () {
    return {
      form: {
        user: '',
        password: '',
        type: 'market'
      },
      rules: {
        user: [{
          required: true,
          message: '请输入账号',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }]
      },
      loading: false
    };
  },
  created () {
    let token = this.getQueryVariable('token');
    let exp = this.getQueryVariable('exp');
    if (!token) {
      token = window.localStorage.getItem('token');
    }
    if (!exp) {
      exp = window.localStorage.getItem('exp');
    }
    window.localStorage.setItem('exp', exp * 1000);
    window.localStorage.setItem('token', token);
    if (token && token !== 'null') {
      this.getuserInfo();
    }
  },
  mounted () {
    document.body.addEventListener('keydown', this.logined);
  },
  destroyed () {
    console.log('移除');
    document.body.removeEventListener('keydown', this.logined)
  },
  methods: {
    getQueryVariable (val) {
      const w = window.location.hash.indexOf('?');
      const query = window.location.hash.substring(w + 1);
      const vars = query.split('&');
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (pair[0] == val) {
          return pair[1];
        }
      }
      return null;
    },
    getuserInfo () {
      this.$request.HttpPost('/refresh').then(res => {
        if (res.data) {
          console.log(res.data);
          res.data.worker.r_name = res.data.worker.name;
          let loginInfo = JSON.stringify(res.data);
          window.localStorage.setItem('exp', res.data.claims.exp * 1000);
          window.localStorage.setItem('token', res.data.token);
          window.localStorage.setItem('userInfo', loginInfo);
          let arr = res.data.role_id.split(",")
          if (!arr.includes('60269')) {
            this.$message({
              type: 'info',
              message: '暂无权限，请联系管理员！'
            });
            return false
          }
          this.$router.push({
            path: 'welcome'
          });
          // this.$store.commit('CHANGEROLEID', res.data.role_id.split(','));
        }
      });
    },
    login () {
      this.$refs.loginform.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$request.HttpPost('/login', this.form).then(res => {
            this.loading = false;
            if (res.data) {
              if (res.data.role_id) {
                let arr = res.data.role_id.split(",")
                if (!arr.includes('60269')) {
                  this.$message({
                    type: 'info',
                    message: '暂无权限，请联系管理员！'
                  });
                  return false
                }
              }
              res.data.worker.r_name = res.data.worker.name;
              window.localStorage.setItem('userInfo', JSON.stringify(res.data));
              window.localStorage.setItem('token', res.data.token);
              window.localStorage.setItem('exp', res.data.claims.exp * 1000);
              // this.$store.commit('getUserInfo', res.data);
              // createInit()
              this.$request.HttpPost('/index/initializePropertySet')
              this.$router.push({
                path: 'welcome'
              });
            }
          })
            .catch(err => {
              // this.$common.notifyError(err)
              this.loading = false
            })
        }
      });
    },
    logined (e) {

      if (e.key == 'Enter' || e.keyCode == 13) {
        this.$refs.loginform ? this.$nextTick(() => {
          this.login();
        }) : '';
      }

    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100vh;
  background: url('../assets/bg.png') no-repeat;
  background-size: 100% 100%;
  position: relative;

  .login-box {
    width: 520px;
    height: 420px;
    background: #FFFFFF;
    border: 1px solid #C8C8C8;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 42px 70px 60px;
    box-sizing: border-box;

    .title {
      display: flex;
      // justify-content: space-between;
      position: relative;

      img {
        width: 80px;
        height: 80px;
      }

      .name {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        align-self: flex-end;
        margin-left: 10px;
      }

      .company {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        flex: 1;
        white-space: nowrap;
        text-align: right;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .form {
      margin-top: 47px;

      .el-input {
        height: 42px;
      }

      /deep/ .el-input__inner {
        // width: 164px;
        height: 42px;
        font-size: 12px;
      }
    }
  }

  .support {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);

    img {
      width: 10px;
      height: 11px;
      margin-right: 5px;
    }

    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #B2C9FF;

  }
}
</style>
